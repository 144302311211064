import { Component } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { getCodeTable, sendHttpRequest } from '../../utils/apiUtils';
import { USER_KEY } from '../hooks/useUser';
import ActionButton from '../layout/ActionButton';
import CurrentOrder from './CurrentOrder';
import ProductListSimple from './ProductListSimple';

const ORDER_KEY = "selectedProducts"
const PAYMENT_TYPE_KEY = "paymentTypes"

class CashRegister extends Component {

    constructor(props) {
        super(props)
        this.state = {
            paymentId: { value: 1, label: "В брой" },
            paidAmount: '',
            toReturn: 0.00,
            error: {}
        }

        this.user = JSON.parse(sessionStorage.getItem(USER_KEY));

        // selected products
        this.selectedProducts = new Map(JSON.parse(sessionStorage.getItem(ORDER_KEY) || "null"))
        this.allProducts = JSON.parse(sessionStorage.getItem(ALL_PRODUCTS_KEY) || "null") ?? []
        this.paymentMethods = JSON.parse(sessionStorage.getItem(PAYMENT_TYPE_KEY) || "null") ?? []
    }

    async componentDidMount() {
        if (!this.allProducts?.length > 0) {
            await sendHttpRequest("/api/wrh/products/allActive?orderBy=displayOrder", "GET", null, this.user)
                .then(async response => {
                    return response.json().then(async productsResponse => {
                        if (response?.status === 403) {
                            if (!this.state.error) {
                                this.showError('Нямате достъп за желаната операция')
                            } else {
                                this.showError("Грешка: " + this.state.error)
                            }
                        } else {
                            this.allProducts = productsResponse
                        }
                    })
                })
        }

        this.paidAmountChanged()

        sessionStorage.removeItem(ORDER_KEY)
        sessionStorage.removeItem(ALL_PRODUCTS_KEY)
        sessionStorage.removeItem(PAYMENT_TYPE_KEY)

        this.forceUpdate()
    }

    componentWillUnmount() {
        sessionStorage.setItem(ORDER_KEY, JSON.stringify(Array.from(this.selectedProducts.entries())))
        sessionStorage.setItem(ALL_PRODUCTS_KEY, JSON.stringify(this.allProducts))
        sessionStorage.setItem(PAYMENT_TYPE_KEY, JSON.stringify(this.paymentMethods))
    }

    setInitialState() {
        this.selectedProducts.clear()
        this.setState({
            paymentId: { value: 1, label: "В брой" },
            paidAmount: '',
            error: {},
        })
    }

    showError = (msg) => {
        this.setState({ error: msg })
        toast.error(
            msg,
            { autoClose: false, onClose: (e) => { this.setState({ error: '' }) } }
        );
    }

    onKeyUp(e) {
        if (e.key === 'Enter') {
            if (this.selectedProducts?.size > 0) {
                var payBtn = document.getElementsByName('pay')[0]
                if (payBtn !== undefined) {
                    payBtn.click()
                }
            }
        }
    }

    paidAmountChanged(e) {
        const cash = e?.target?.value ?? e ?? this.state.paidAmount

        var total = 0
        this.selectedProducts.forEach((v, k) => {
            total += (v.prd.priceSell * v.qty)
        })


        this.setState({
            ...this.state,
            paidAmount: cash,
            toReturn: (cash - total),
        })
    }

    addProductToOrder(prd) {
        const current = this.selectedProducts.get(prd.id)
        if (current !== undefined) {
            current.qty++
        } else {
            this.selectedProducts.set(prd.id, { "prd": prd, "qty": 1 })
        }

        // find the product in the all products list and reduce the available quantity
        this.allProducts
            .filter(e => e.id === prd.id && e.productType.countable)
            .map(e => e.quantity--);


        this.paidAmountChanged()
        this.forceUpdate()
    }

    removeProductFromOrder(prd) {
        const current = this.selectedProducts.get(prd.id)

        if (current.qty === 1) {
            this.selectedProducts.delete(prd.id)
        } else {
            current.qty--
        }

        // find the product in the all products list and increase the available quantity
        this.allProducts
            .filter(e => e.id === prd.id && e.productType.countable)
            .map(e => e.quantity++);

        this.paidAmountChanged()
        this.forceUpdate()
    }

    getPaymentMethods(inputValue, callback) {
        if (this.paymentMethods?.length > 0) {
            callback(this.paymentMethods)
        } else {
            getCodeTable("/api/config/paymentTypes", this.user).then(data => {
                this.paymentMethods = data;
                callback(this.paymentMethods)
            })
        }
    }

    async handlePayment(e) {

        const orderItems = []

        this.selectedProducts.forEach((v, k) => {
            orderItems.push({
                productId: v.prd.id,
                quantity: v.qty,
                discount: 0,
            })
        })

        const reqBody = {
            totalAmount: 0,
            paymentType: { id: this.state.paymentId.value },
            orderItems: orderItems,
        }

        await sendHttpRequest("/api/wrh/orders/sale", "POST", reqBody, this.user)
            .then(async response => {
                return response.json().then(async result => {
                    if (result?.id) {
                        toast.success(`Поръчката ${result.id}, сума ${result.amount.toFixed(2)} лв`, {
                            autoClose: 800
                        });

                        this.setInitialState()
                    } else {
                        toast.error(result?.message, { autoClose: false, onClose: (e) => { this.setState({ ...this.state, error: '' }) } })
                    }
                })
            })
    }

    render() {
        return (<>
            <Row>
                <Col sm="6">
                    <Card className="border border-dark bg-dark-dpool text-white centerElement" key='products' style={{ margin: "10px 0px 10px 0px" }}>
                        <Card.Header className="center" style={{ cursor: 'pointer' }}>
                            <h3>Текуща сметка</h3>
                        </Card.Header>

                        <CurrentOrder selectedProducts={this.selectedProducts}
                            onRemoveClickHandler={this.removeProductFromOrder.bind(this)} onAddClickHandler={this.addProductToOrder.bind(this)} />

                        <Form.Row  >
                            <Form.Group as={Col} sm="12" style={{ display: "flex" }} >
                                <Col sm="2"><Form.Label>Платете: </Form.Label></Col>
                                <Col sm="5" style={{ color: "black" }}>
                                    <AsyncSelect defaultOptions loadOptions={this.getPaymentMethods.bind(this)} value={this.state.paymentId}
                                        onChange={(e) => { this.setState({ ...this.state, paymentId: e }) }} isDisabled={this.selectedProducts.size === 0} />
                                </Col>
                                <Col sm="5">
                                    <Form.Control type="text" value={this.state.paidAmount} onKeyUp={this.onKeyUp.bind(this)}
                                        onChange={this.paidAmountChanged.bind(this)} disabled={this.state.paymentId.value !== 1} />
                                </Col>

                            </Form.Group>
                            <Form.Group as={Col} sm="12" style={{ display: "flex" }}>
                                <Col sm="2">
                                    <Form.Label>Ресто: </Form.Label>
                                </Col>
                                <Col sm="5">
                                    <Form.Control type="text" value={this.state.toReturn?.toFixed(2)} disabled={true} />
                                </Col>
                                <Col sm="5" className="float-right">
                                    <ActionButton size="sm" variant="info" type="button" name="pay" className="form-control float-right"
                                        onClick={this.handlePayment.bind(this)} disabled={!(this.selectedProducts?.size > 0)} >
                                        Плащане</ActionButton>
                                </Col>
                            </Form.Group>
                        </Form.Row>
                    </Card>

                </Col>
                <Col sm="6">
                    <Card className="border border-dark bg-dark-dpool text-white centerElement" key='products' style={{ margin: "10px 0px 10px 0px" }}>
                        <Card.Header className="center" style={{ cursor: 'pointer' }}>
                            <h3>Продукти и услуги</h3>
                        </Card.Header>

                        <ProductListSimple onClickHandler={this.addProductToOrder.bind(this)} products={this.allProducts} />
                    </Card>
                </Col>
            </Row>
        </>);
    }
}

CashRegister.propTypes = {

};

export default CashRegister;
export const ALL_PRODUCTS_KEY = "allProducts"
